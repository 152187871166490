import type {
    Customer,
    ICustomerPaymentProvidersData,
    JobOccurrence,
    Service,
    SkipCharge,
    SkipReason,
    StoredObject,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Invoice,
    InvoiceItem,
    InvoiceTransaction,
    JobOccurrenceStatus,
    PaymentDetails,
    TransactionType,
    getTaxConfig,
    replaceMessageTokensWithModelValues,
    sortByCreatedDateDesc,
    to2dp,
    uuid,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import AutomaticPaymentsService from '../AutomaticPayments/AutomaticPaymentsService';
import { CustomerCreditDialog } from '../Customers/Components/CustomerCreditDialog';
import { CustomerService } from '../Customers/CustomerService';
import { Data } from '../Data/Data';
import type { DataChangeNotificationType } from '../Data/DataChangeNotificationType';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { JobService } from '../Jobs/JobService';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../Notifications/SendNotificationService';
import { Api } from '../Server/Api';
import { TransactionService } from '../Transactions/TransactionService';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { Utilities } from '../Utilities';
import { t } from '../t';
import type { InvoiceMessageModel } from './InvoiceMessageModel';
import { InvoiceTransactionSummaryDialog } from './InvoiceTransactionSummaryDialog';
import { updateInvoicePriceForSkippedReason } from './updateInvoicePriceForSkippedReason';

export class InvoiceService {
    public static createInvoiceForCustomer(customer: Customer) {
        const paymentPeriod = customer.paymentPeriod !== undefined ? customer.paymentPeriod : ApplicationState.account.paymentPeriod || 0;
        const date = moment();
        //TODO Check taxrate can be undefined so why does invoice require it to be set
        const { taxEnabled, priceIncludesTax, taxRate } = getTaxConfig(customer, ApplicationState.account);

        const invoice = new Invoice(priceIncludesTax, taxRate === undefined ? 0 : taxRate, taxEnabled);

        const defaultNotes =
            (ApplicationState.account.invoiceSettings.defaultNotes && ApplicationState.account.invoiceSettings.defaultNotes + '\n\n') || '';
        invoice.notes = `${customer.hideDefaultInvoiceNotes === true ? '' : defaultNotes}${customer.invoiceNotes || ''}`;
        invoice.customerId = customer._id;
        invoice.billTo = `${customer.name}${customer.address.addressDescription ? ', ' + customer.address.addressDescription : ''}`;
        invoice.emailTo = customer.email || '';
        invoice.currency = ApplicationState.currencyCode();
        invoice.date = date.format();
        invoice.dueDate = date.add(paymentPeriod, 'days').format();
        return invoice;
    }

    public static createCreditInvoiceForCustomer(date: string, customerId: string, amount: number, description: string) {
        if (amount > 0) amount = -amount;
        const invoice = new Invoice(
            ApplicationState.account.invoiceSettings.priceIncludesTax,
            ApplicationState.account.invoiceSettings.taxRate ? ApplicationState.account.invoiceSettings.taxRate / 100 : 0,
            ApplicationState.account.invoiceSettings.taxEnabled
        );
        invoice.notes =
            (ApplicationState.account.invoiceSettings.defaultNotes && ApplicationState.account.invoiceSettings.defaultNotes) || '';
        invoice.customerId = customerId;
        invoice.total = amount;
        invoice.subtotal = amount;
        invoice.currency = ApplicationState.currencyCode();
        invoice.date = date;
        invoice.billTo = '';
        invoice.emailTo = '';
        invoice.dueDate = invoice.date;
        invoice.items = [{ description, quantity: 1, refID: invoice.referenceNumber, resourceType: 'transactions', unitPrice: amount }];
        return invoice;
    }

    public static quickCreateInvoice(r: {
        date: string;
        customerId: string;
        description: string;
        invoiceTotal: number;
        paymentId?: string;
    }) {
        if (!r.customerId) throw 'Need customer id to create invoice';
        if (!r.invoiceTotal) throw 'Need total amount to create invoice';
        if (!r.date) throw 'Date required for invoice';

        const customer = Data.get<Customer>(r.customerId);
        if (!customer) throw 'Customer not found';

        const invoice = InvoiceService.createInvoiceForCustomer(customer);
        invoice.date = r.date;
        invoice.items = [new InvoiceItem(1, r.description, r.invoiceTotal, '', '')];

        invoice.paymentReference = r.paymentId || '';

        invoice.total = r.invoiceTotal;

        return new InvoiceTransaction('invoice.standard', customer._id, invoice);
    }

    public static async autoCreateInvoiceForOccurrence(
        customer: Customer,
        occurrence: JobOccurrence,
        suppressScheduleActionEvent = false,
        payment?: Transaction,
        allowTriggerPayments = true,
        backdateInvoice = false
    ) {
        if (!occurrence) throw 'Cannot auto create invoice as the appointment is not saved.';

        if (occurrence.status === JobOccurrenceStatus.Skipped) {
            if (!ApplicationState.hasUltimateOrAbove) return;

            if (!ApplicationState.getSetting<SkipCharge | undefined>('global.skip-charge-default')) {
                const skipReason = occurrence.skipReason;
                if (!skipReason) return;

                const reasons = ApplicationState.getSetting<Array<SkipReason> | undefined>('global.skip-reasons-and-charges');
                if (!reasons?.find(x => x.name === skipReason)?.skipCharge) return;
            }
        }

        if (
            occurrence.invoiceTransactionId === 'processing' ||
            (occurrence.customerId && InvoiceService.occurrenceAlreadyInvoiced(occurrence._id, occurrence.customerId))
        )
            return;

        occurrence.invoiceTransactionId = 'processing';

        const invoice = InvoiceService.createInvoiceForCustomer(customer);
        if (backdateInvoice && occurrence)
            invoice.date = (occurrence.isoCompletedDate || occurrence.isoPlannedDate || occurrence.isoDueDate).slice(0, 10);

        invoice.items = InvoiceService.occurrenceToInvoiceItems(
            occurrence,
            !!occurrence.location && customer.address.addressDescription !== occurrence.location.addressDescription,
            true
        );

        Invoice.updateTotals(invoice);

        if (occurrence.status === JobOccurrenceStatus.Skipped) {
            updateInvoicePriceForSkippedReason(invoice, occurrence.skipReason);
        }

        Invoice.updateTotals(invoice);

        if (payment && Math.abs(payment.amount) >= invoice.total) {
            invoice.paymentReference = payment._id;
            occurrence.paymentTransactionId = payment._id;
        }

        return await InvoiceService.submitInvoice(
            invoice,
            customer,
            [occurrence],
            suppressScheduleActionEvent,
            allowTriggerPayments,
            backdateInvoice
        );
    }
    static occurrenceAlreadyInvoiced(occurrenceId: string, customerId: string): boolean {
        return Data.exists<Transaction>(
            'transactions',
            t =>
                (t.transactionType === TransactionType.Invoice &&
                    !t.voidedId &&
                    !t._deleted &&
                    t.invoice &&
                    t.invoice.items &&
                    t.invoice.items.some(i => i.refID === occurrenceId)) ||
                false,
            { customerId }
        );
    }

    public static updateTotals(invoice: Invoice) {
        let itemsTotal = 0;
        for (const item of invoice.items || []) {
            itemsTotal += item.unitPrice * item.quantity;
        }

        if (Invoice.resolveTaxEnabled(invoice)) {
            if (invoice.priceIncludesTax) {
                invoice.total = to2dp(itemsTotal);
                invoice.subtotal = to2dp(invoice.total / (1 + invoice.taxRate));
                invoice.totalTax = invoice.total - invoice.subtotal;
            } else {
                invoice.subtotal = to2dp(itemsTotal);
                invoice.totalTax = to2dp(invoice.subtotal * invoice.taxRate);
                invoice.total = invoice.subtotal + invoice.totalTax;
            }
        } else {
            invoice.total = to2dp(itemsTotal);
            invoice.subtotal = to2dp(itemsTotal);
        }
    }

    public static occurrenceToInvoiceItems(occurrence: JobOccurrence, includeOccurrenceAddress: boolean, showJobInvoiceNotes: boolean) {
        const invoiceItems: Array<InvoiceItem> = [];

        const isPricedByService =
            occurrence.price !== 0 &&
            occurrence.services
                .map(x => (Number((x as Service).price) || 0) * (Number((x as Service).quantity) || 1))
                .reduce((x: number, y) => x + (y || 0), 0) === occurrence.price;
        if (isPricedByService) {
            for (let i = 0; i < occurrence.services.length; i++) {
                const service = <Service>occurrence.services[i];

                let description =
                    `${moment(occurrence.isoCompletedDate || occurrence.isoPlannedDate || occurrence.isoDueDate).format('LL')} | ` +
                    (includeOccurrenceAddress && occurrence.location ? occurrence.location.addressDescription + ': ' : '') +
                    service.description;

                if (showJobInvoiceNotes && i === occurrence.services.length - 1) {
                    const job = JobService.getJob(occurrence.customerId, occurrence.jobId);
                    if (job && job.invoiceNotes && job.invoiceNotes.trim()) {
                        description += `\n${job.invoiceNotes.trim()}`;
                    }
                }

                invoiceItems.push(
                    new InvoiceItem(
                        service.quantity || 1,
                        description,
                        Number(service.price) || 0,
                        occurrence.resourceType,
                        occurrence._id,
                        undefined,
                        undefined,
                        service.longDescription
                    )
                );
            }
            if (invoiceItems.length) return invoiceItems;
        }

        // Single item
        const servicesDescription = occurrence.services.map(s => s.description).join(', ');
        const servicesLongDescription = occurrence.services.map(s => s.longDescription).join(', ');

        let description =
            (includeOccurrenceAddress && occurrence.location ? occurrence.location.addressDescription + ': ' : '') +
            servicesDescription +
            ` | ${moment(occurrence.isoCompletedDate || occurrence.isoPlannedDate || occurrence.isoDueDate).format('LL')}`;

        if (showJobInvoiceNotes) {
            const job = JobService.getJob(occurrence.customerId, occurrence.jobId);
            if (job && job.invoiceNotes && job.invoiceNotes.trim()) {
                description += `\n${job.invoiceNotes.trim()}`;
            }
        }

        invoiceItems.push(
            new InvoiceItem(
                1,
                description,
                occurrence.price || 0,
                occurrence.resourceType,
                occurrence._id,
                undefined,
                undefined,
                servicesLongDescription
            )
        );

        return invoiceItems;
    }

    public static async submitInvoice(
        invoice: Invoice,
        customer: Customer,
        occurrences: Array<JobOccurrence>,
        suppressScheduleActionEvent = false,
        allowTriggerPayments = true,
        backdatedInvoice = false,
        showUserInvoiceSummary = false
    ): Promise<Transaction | undefined> {
        try {
            // Pause q so that this whole thing can happen before the server starts messing with the data
            Api.stopProcessingSyncQueue();
            const invoiceTransaction = new InvoiceTransaction('invoice.standard', customer._id, invoice);

            for (const jobOccurrence of occurrences) {
                if (jobOccurrence) {
                    const job = Utilities.copyObject(customer.jobs[jobOccurrence.jobId]);
                    if (job && job.isQuote) {
                        job.isQuote = false;
                        await JobService.addOrUpdateJob(job.customerId, job);
                    }
                    jobOccurrence.invoiceTransactionId = invoiceTransaction._id;
                    jobOccurrence.invoicedDate =
                        (invoiceTransaction.invoice && invoiceTransaction.invoice.date) ||
                        invoiceTransaction.date ||
                        invoiceTransaction.createdDate;
                    jobOccurrence.paymentStatus = 'invoiced';
                    invoiceTransaction.jobId = jobOccurrence.jobId;
                }
            }

            const customerBalanceBEFOREInvoice = TransactionService.getCustomerBalance(customer._id, moment().format('YYYY-MM-DD'));
            const itemsToUpdate: Array<StoredObject> = occurrences.slice();
            itemsToUpdate.push(invoiceTransaction);
            const notify: DataChangeNotificationType = suppressScheduleActionEvent ? 'lazy' : 'immediate';
            const itemsToActuallyUpdate = itemsToUpdate.filter((item, index) => itemsToUpdate.lastIndexOf(item) === index);
            await Data.put(itemsToActuallyUpdate, true, notify);

            if (showUserInvoiceSummary) new InvoiceTransactionSummaryDialog(invoiceTransaction).show(DialogAnimation.SLIDE_UP);

            if (!backdatedInvoice && allowTriggerPayments && invoice.total > 0) {
                const chargeUpdates = await InvoiceService.generateAutomaticChargeIfRequired(
                    customer,
                    invoiceTransaction,
                    occurrences,
                    customerBalanceBEFOREInvoice.amount,
                    suppressScheduleActionEvent
                );
                if (chargeUpdates?.length) {
                    await Data.put(chargeUpdates, true, notify);
                }
            }

            await InvoiceService.allocateBalanceToInvoices(customer._id).catch(error =>
                Logger.error('unable to allocate balance to invoices during invoice submission', { error }, 3)
            );

            if (
                !backdatedInvoice &&
                (invoice.total > 0 || ApplicationState.getSetting('global.invoice-auto-send-invoices-for-zero-value-jobs', false)) &&
                (customer.autoInvoiceNotification === 'auto' ||
                    (!customer.autoInvoiceNotification &&
                        ApplicationState.account.invoiceSettings.defaultAutoInvoiceNotification === 'auto'))
            ) {
                switch (customer.defaultNotificationMethod) {
                    case 'email':
                        this.sendInvoiceByEmail(
                            invoiceTransaction,
                            (invoiceTransaction.invoice && invoiceTransaction.invoice.emailTo) || ''
                        );
                        break;
                    case 'sms':
                    case 'sms2':
                        this.sendInvoiceBySms([invoiceTransaction], true);
                        break;
                    case 'email-and-sms':
                    case 'email-and-sms2':
                        this.sendInvoiceByEmail(
                            invoiceTransaction,
                            (invoiceTransaction.invoice && invoiceTransaction.invoice.emailTo) || ''
                        );
                        this.sendInvoiceBySms([invoiceTransaction], true);
                }
            }

            return invoiceTransaction;
        } catch (error) {
            Logger.error('Error in submitInvoice on InvoiceService', { invoice, customer, occurrences, error }, 2);
            throw error;
        } finally {
            Api.processSyncQueue();
        }
    }

    static hasCustomAutomaticPaymentMethod(customer: Customer) {
        const customPaymentMethods = ApplicationState.getSetting<Record<string, string | undefined>>('global.custom-payment-methods', {});

        const automaticPaymentMethod = customer.automaticPaymentMethod;

        return !!(automaticPaymentMethod && customPaymentMethods[automaticPaymentMethod]) || false;
    }

    private static async generateAutomaticChargeIfRequired(
        customer: Customer,
        invoiceTransaction: Transaction,
        occurrences: Array<JobOccurrence>,
        customerBalance: number,
        bulkAction: boolean
    ): Promise<Array<StoredObject> | undefined> {
        if (invoiceTransaction.invoice && invoiceTransaction.invoice.paid) return;
        if (!invoiceTransaction.amount) return;
        if (!customer.automaticPaymentMethod) return;
        if (!customer.takePaymentOnInvoiced) return;

        const hasCustomAutomaticPaymentMethod = InvoiceService.hasCustomAutomaticPaymentMethod(customer);

        if (!hasCustomAutomaticPaymentMethod) {
            const automaticPaymentMethod = customer.automaticPaymentMethod as keyof ICustomerPaymentProvidersData;
            if (!customer.paymentProviderMetaData) return;

            if (!customer.paymentProviderMetaData[automaticPaymentMethod]?.sourceOrMandateId) return;
            const status = customer.paymentProviderMetaData[automaticPaymentMethod]?.status;
            if (!status || !['active', 'pending'].includes(status)) return;
        }

        if (bulkAction && !ApplicationState.account.invoiceSettings.autoChargeInvoiceMinusCredit) return;

        // Credit balance means < 0;
        const invoiceAmount = invoiceTransaction.amount || 0;

        if (-customerBalance >= invoiceAmount) {
            if (!bulkAction) new NotifyUserMessage('notification.invoiced-paid-by-credit-balance');
            return;
        }

        const chargeAmount = customerBalance < 0 ? invoiceAmount + customerBalance : invoiceAmount;

        if (chargeAmount <= 0) return;

        if (!ApplicationState.account.invoiceSettings.autoChargeInvoiceMinusCredit && !bulkAction) {
            const dialog = await CustomerCreditDialog.createForInvoice(customer, invoiceTransaction, true);
            if (dialog) await dialog.show();
            return;
        }

        if (!ApplicationState.account.invoiceSettings.autoChargeInvoiceMinusCredit) return;

        // WTF Ok this is to prevent the same occurrence payment being requested by dd.
        const invoiceOccurrenceId = invoiceTransaction?.invoice?.items?.[0].refID;

        const idempotencyKey = InvoiceService.generateIdempotencyKey(occurrences[0], [invoiceTransaction]);
        const automaticPaymentSubType = AutomaticPaymentsService.getAutomaticPaymentTransactionTypeForCustomer(customer);

        const paymentTransaction = await TransactionService.createAutomaticPayment({
            paymentProvider: customer.automaticPaymentMethod,
            type: automaticPaymentSubType,
            customerId: customer._id,
            amount: chargeAmount,
            additionalReference: '',
            initiatorReference: 'auto',
            invoiceIds: [invoiceTransaction._id],
            idempotencyKey,
            jobOrOccurrenceId: invoiceOccurrenceId,
        });

        if (!paymentTransaction) return;
        (invoiceTransaction.invoice as Invoice).paymentReference = paymentTransaction._id;

        const itemsUpdates = [];

        itemsUpdates.push(paymentTransaction);
        itemsUpdates.push(invoiceTransaction);

        // if last payment method has changed update it
        if (customer.lastPaymentMethod !== automaticPaymentSubType) {
            customer.lastPaymentMethod = automaticPaymentSubType;
            itemsUpdates.push(customer);
        }

        for (let index = 0; index < occurrences.length; index++) {
            const occurrence = occurrences[index];
            if (occurrence.paymentTransactionId !== paymentTransaction._id) {
                occurrence.paymentTransactionId = paymentTransaction._id;
                itemsUpdates.push(occurrence);
            }
        }

        return itemsUpdates;
    }

    public static generateIdempotencyKey(occurrence?: JobOccurrence, invoices?: Array<Transaction>): string {
        let reference: string | undefined;
        if (occurrence) {
            reference = occurrence._id;
        } else if (invoices?.length) {
            reference = invoices
                .map(t => t?.invoice?.items?.[0]?.refID || undefined)
                .filter((x): x is string => typeof x === 'string' && !!x)
                .sort()
                .join('_');

            if (!reference)
                reference = invoices
                    .map(i => i._id)
                    .sort()
                    .join('_');
        }

        if (!reference) return uuid();

        // Trim the reference to the max size (128 chars for GC, 255 for Stripe)
        // minus 4 characters to allow an underscore and up to 999 retry identifiers.
        reference = reference.slice(0, 124);

        const validReference = reference;
        const customerId = occurrence?.customerId || invoices?.[0]?.customerId;

        // If there is no customer ID then treat this like the first attempt for this reference.
        if (!customerId) return reference + '_001';

        const isValidReference = (t: Transaction) => !!t.paymentDetails?.reference?.startsWith(validReference);
        const payments = Data.all<Transaction>('transactions', isValidReference, { customerId: customerId });

        const attempts = payments.length;

        // This is the first attempt for this reference.
        if (!attempts) return reference + '_001';

        const failed = payments.filter(t => t.status === 'canceled' || t.status === 'failed').length;
        if (attempts === failed) return reference + '_' + attempts.toString().padStart(3, '0');

        return reference;
    }

    public static async allocateBalanceToInvoices(customerId: string) {
        const balance = TransactionService.getCustomerBalance(customerId, moment().format('YYYY-MM-DD'));

        const invoiceTransactions = await TransactionService.getCustomerInvoicesExcludingVoids(customerId).sort(sortByCreatedDateDesc);
        const toUpdate = [];

        for (const invoiceTransaction of invoiceTransactions) {
            const paymentRef = (invoiceTransaction.invoice && invoiceTransaction.invoice.paymentReference) || undefined;
            let hasPayment = !!paymentRef;
            if (paymentRef && !Data.get(paymentRef)) {
                invoiceTransaction.invoice && (invoiceTransaction.invoice.paymentReference = undefined as any);
                hasPayment = false;
            }

            const paid = hasPayment || balance.amount <= 0;

            const updated = await InvoiceService.setInvoicePaymentStatus(invoiceTransaction, paid);
            if (updated && updated.length) toUpdate.push(...updated);

            // If invoice has a payment reference then don't subtract the payment from the balance as it already has been deducted from balance
            if (hasPayment === false) {
                balance.amount = to2dp(balance.amount - invoiceTransaction.amount);
            }
        }

        if (toUpdate.length) Data.put(toUpdate, true, 'lazy', false);
    }

    public static async setInvoicePaymentStatus(invoiceTransaction: Transaction, paid: boolean) {
        const toUpdate: Array<StoredObject> = [];

        if (!invoiceTransaction.invoice) return toUpdate;

        let invoiceUpdated: boolean | undefined;
        const today = moment().format('YYYY-MM-DD');
        if (invoiceTransaction.invoice.paid !== paid) {
            invoiceTransaction.invoice.paid = paid;

            invoiceUpdated = true;
        }

        if (invoiceTransaction.invoice.paymentReference) {
            const allocatedPayment = Data.get<Transaction>(invoiceTransaction.invoice.paymentReference);
            if (allocatedPayment) {
                if (!allocatedPayment.paymentDetails) {
                    allocatedPayment.paymentDetails = new PaymentDetails();
                }
                if (!allocatedPayment.paymentDetails.invoiceIds) allocatedPayment.paymentDetails.invoiceIds = [];

                if (allocatedPayment.paymentDetails.invoiceIds.indexOf(invoiceTransaction._id) === -1) {
                    Logger.info('****** Payment does not have invoice allocated' + invoiceTransaction._id);
                    allocatedPayment.paymentDetails.invoiceIds.push(invoiceTransaction._id);
                    toUpdate.push(allocatedPayment);
                }
            }
        }

        if (!invoiceTransaction.invoice.items) return toUpdate;

        for (const invoiceItem of invoiceTransaction.invoice.items) {
            if (!invoiceItem.refID) continue;

            const possibleOccurrence = JobOccurrenceService.getOccurrence(invoiceItem.refID);

            if (!possibleOccurrence || possibleOccurrence.resourceType !== 'joboccurrences') continue;

            if (JobOccurrenceService.resolveOccurrenceStatus(possibleOccurrence, today, invoiceTransaction))
                toUpdate.push(possibleOccurrence);
        }

        if (invoiceUpdated) toUpdate.push(invoiceTransaction);

        return toUpdate;
    }

    public static async cancelOrVoidInvoice(
        invoiceTranId: string,
        suppressEvents = false,
        objectsToPersist?: { [id: string]: StoredObject },
        deleteOnCancel?: 'onSuccess' | 'always'
    ) {
        if (!objectsToPersist) objectsToPersist = {};

        const transaction = TransactionService.getTransaction(invoiceTranId);

        if (!transaction || !transaction.invoice) return;

        if (transaction.invoice.paymentReference) {
            const paymentTran = TransactionService.getTransaction(transaction.invoice.paymentReference);
            if (paymentTran) {
                if (
                    paymentTran.transactionType === TransactionType.AutomaticPayment &&
                    paymentTran.transactionSubType === 'auto.go-cardless'
                ) {
                    await TransactionService.cancelTransaction(paymentTran, suppressEvents, objectsToPersist, deleteOnCancel);
                }
                transaction.invoice.paymentReference = '';
                transaction.invoice.paid = false;
            }
        }

        const jobOccurrences = Data.all<JobOccurrence>('joboccurrences', x => x.invoiceTransactionId === transaction._id);

        for (const occurrence of jobOccurrences) {
            occurrence.invoicedDate = undefined;
            occurrence.invoiceTransactionId = undefined;
            occurrence.paymentStatus = undefined;
            occurrence.paidDate = undefined;
            objectsToPersist[occurrence._id] = occurrence;
        }

        await TransactionService.cancelTransaction(transaction, suppressEvents, objectsToPersist, deleteOnCancel);
    }

    public static async sendInvoiceBySms(invoiceTransactions: Array<Transaction>, noConfirm = false, isManual = false): Promise<boolean> {
        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return false;
        }

        for (const invoiceTransaction of invoiceTransactions.filter(x => x.customerId && x.invoice)) {
            const invoice = <Invoice>invoiceTransaction.invoice;
            const customerId = <string>invoiceTransaction.customerId;

            let templateText = ApplicationState.messagingTemplates?.smsInvoice?.trim() || '';

            const customer = CustomerService.getCustomer(customerId);
            const assignees: Array<string> = [];
            const jobOccurrences: Array<JobOccurrence> = [];
            for (const item of invoice.items || []) {
                const possibleOccurrence = Data.get<JobOccurrence>(item.refID);
                if (possibleOccurrence && possibleOccurrence.resourceType === 'joboccurrences') {
                    jobOccurrences.push(possibleOccurrence);
                    assignees.push(...AssignmentService.getAssignees(possibleOccurrence).map(x => x.name));
                }
            }

            const services: Array<string> = [];

            for (const occurrence of jobOccurrences) {
                for (const service of occurrence.services || []) {
                    service && service.description && services.push(service.description as TranslationKey);
                }
            }

            if (!customer) throw 'Unable to find customer';

            const invoiceMessageModel: InvoiceMessageModel = {
                total: `${ApplicationState.currencySymbol()}${invoiceTransaction.amount.toFixed(2)}`,
                reference: invoice.referenceNumber,
                invoiceNumber: (invoice.invoiceNumber && invoice.invoiceNumber.toFixed(0)) || '',
                url: InvoiceService.generateInvoiceUrl(invoice.referenceNumber),
                services: Utilities.filterDuplicateStrings(services).join(', ') || '',
                assignees:
                    Utilities.filterDuplicateStrings(assignees).join(', ') ||
                    ApplicationState.account.businessName ||
                    ApplicationState.account.name,
                formattedDate: invoice.date && moment(invoice.date).format('dddd, MMMM Do'),
                formattedDueDate: invoice.dueDate && moment(invoice.dueDate).format('dddd, MMMM Do'),
            };

            const messageModel = await Utilities.getStandardMessageModel({
                customer,
                isHtml: false,
                additionalModelProperties: invoiceMessageModel,
                templateText,
            });

            templateText = replaceMessageTokensWithModelValues({
                model: messageModel,
                message: templateText,
                options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
            });

            if (isManual) {
                new LoaderEvent(false);
                const textDialog = new TextDialog(
                    'Confirm Message' as TranslationKey,
                    '',
                    templateText,
                    '',
                    v => (v ? true : ('The email text is required' as TranslationKey)),
                    false,
                    'textarea',
                    'general.send'
                );
                templateText = await textDialog.show();
                if (textDialog.cancelled) return false;
            } else if (invoiceTransaction === invoiceTransactions[0] && !noConfirm) {
                const confirmPrompt = new Prompt(
                    'prompts.sample-message-title',
                    <TranslationKey>(
                        `${ApplicationState.localise('prompts.sample-message-text')}<br><br>${templateText.replace(/\n/g, '<br>')}`
                    ),
                    {
                        okLabel: 'general.send',
                        cancelLabel: 'general.cancel',
                        coverViewport: true,
                        cssClass: 'scrollable-cover',
                    }
                );

                if (!(await confirmPrompt.show())) return false;
            }

            let number: string | undefined;
            if (!customer.defaultNotificationMethod || customer.defaultNotificationMethod === 'email') {
                const numberOptions: Array<{ text: TranslationKey; value: string }> = [];

                if (customer.telephoneNumber && customer.telephoneNumber.length)
                    numberOptions.push({ text: <TranslationKey>('Primary:' + customer.telephoneNumber), value: customer.telephoneNumber });
                if (customer.telephoneNumberOther && customer.telephoneNumberOther.length)
                    numberOptions.push({
                        text: <TranslationKey>('Other:' + customer.telephoneNumberOther),
                        value: customer.telephoneNumberOther,
                    });

                if (!numberOptions.length && !noConfirm) {
                    const dialog = new Prompt('no.sms-number', 'no.telephone-for-sms-on-customer');
                    await dialog.show();
                    return false;
                }
                if (!noConfirm) {
                    const dialog = new Select('select.number-send-invoice', numberOptions, 'text', 'value', undefined, {
                        coverViewport: true,
                    });
                    const result = await dialog.show(DialogAnimation.SLIDE);
                    if (dialog.cancelled) return false;
                    number = result.value;
                }
            } else {
                number = <string>InvoiceService.getNumber(customer);
            }

            if (!number || !number.length) {
                const dialog = new Prompt('no.sms-number', 'no.telephone-for-sms-on-customer');
                await dialog.show();
                return false;
            }

            const sent = await SendNotificationService.send(
                'Invoice ' + invoice.referenceNumber,
                number,
                customer._id,
                'SMS',
                SendNotificationService.notificationFrom,
                templateText,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                invoiceTransaction._id
            );
            if (sent) {
                new NotifyUserMessage('notifications.invoice-send-success', { email: customer.name });
            } else {
                // TODO: Review what we we should show user if failed
                new NotifyUserMessage(<TranslationKey>(ApplicationState.localise('general.failed') + ': ' + customer.name));
            }
        }
        return true;
    }

    private static getNumber(customer: Customer) {
        if (customer.defaultNotificationMethod === 'sms' || customer.defaultNotificationMethod === 'email-and-sms') {
            return customer.telephoneNumber;
        }
        return customer.telephoneNumberOther;
    }

    public static async sendInvoiceByEmail(invoiceTransaction: Transaction, email: string, isManual = false) {
        if (!invoiceTransaction.customerId || !invoiceTransaction.invoice) return false;

        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return false;
        }

        const fromName = SendNotificationService.notificationFrom;

        const invoice = invoiceTransaction.invoice as Invoice;
        const customerId = invoiceTransaction.customerId as string;

        let templateText = ApplicationState.messagingTemplates?.emailInvoice.trim() || '';

        let isHtml = !!ApplicationState.messagingTemplates?.emailInvoiceIsHtml;

        const customer = CustomerService.getCustomer(customerId);

        const jobOccurrences: Array<JobOccurrence> = [];
        const assignees: Array<string> = [];
        for (const item of invoice.items || []) {
            const possibleOccurrence = Data.get<JobOccurrence>(item.refID);
            if (possibleOccurrence && possibleOccurrence.resourceType === 'joboccurrences') {
                jobOccurrences.push(possibleOccurrence);
                assignees.push(...AssignmentService.getAssignees(possibleOccurrence).map(x => x.name));
            }
        }

        let services: Array<string> = [];

        if (jobOccurrences.length) {
            for (const occurrence of jobOccurrences) {
                for (const service of occurrence.services || []) {
                    service && service.description && services.push(service.description as TranslationKey);
                }
            }
        } else {
            services = invoiceTransaction.invoice.items?.map(item => item.description as TranslationKey) || [];
        }

        if (!customer) throw 'Unable to find customer';

        const invoiceMessageModel: InvoiceMessageModel = {
            total: `${ApplicationState.currencySymbol()}${invoiceTransaction.amount.toFixed(2)}`,
            reference: invoice.referenceNumber,
            invoiceNumber: (invoice.invoiceNumber && invoice.invoiceNumber.toFixed(0)) || '',
            url: InvoiceService.generateInvoiceUrl(invoice.referenceNumber),
            services: Utilities.filterDuplicateStrings(services).join(', ') || '',
            assignees:
                Utilities.filterDuplicateStrings(assignees).join(', ') ||
                ApplicationState.account.businessName ||
                ApplicationState.account.name,
            formattedDate: invoice.date && moment(invoice.date).format('ddd, MMM Do'),
            formattedDueDate: invoice.dueDate && moment(invoice.dueDate).format('ddd, MMM Do'),
        };

        const messageModel = await Utilities.getStandardMessageModel({
            customer,
            isHtml,
            additionalModelProperties: invoiceMessageModel,
            templateText,
        });

        templateText = replaceMessageTokensWithModelValues({
            model: messageModel,
            message: templateText,
            options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
        });

        if (isManual) {
            new LoaderEvent(false);
            const textDialog = new TextDialog(
                'Confirm Message' as TranslationKey,
                '',
                templateText,
                '',
                v => (v ? true : ('The email text is required' as TranslationKey)),
                false,
                'tinymce',
                'general.send'
            );
            templateText = await textDialog.show();
            if (textDialog.cancelled) return;

            isHtml = true;
        }

        const invoiceSubject = invoice.invoiceNumber
            ? `${fromName} Invoice #${invoice.invoiceNumber} for ${moment(invoice.date).format('ll')}`
            : `${fromName} Invoice for ${moment(invoice.date).format('ll')}`;

        const sent = await SendNotificationService.send(
            invoiceSubject,
            email,
            invoice.customerId,
            'Email',
            fromName,
            { content: templateText, isHtml, invoice: invoice, customerId: invoice.customerId },
            ApplicationState.account.bccEmailNotifications,
            undefined,
            undefined,
            undefined,
            'emailInvoice1',
            invoiceTransaction._id
        );

        if (sent) new NotifyUserMessage('notifications.invoice-send-success', { email });
        else new NotifyUserMessage('notifications.invoice-send-error');

        return true;
    }

    public static generateInvoiceUrl(ref: string, pdf = false) {
        return `${Api.apiEndpoint}/go/i/${encodeURIComponent(ref)}` + (pdf ? '?format=pdf' : '');
    }
}
